<template>
    <div>
        <div v-if="compatiblePackageConfiguration === false">
            <p>You have made customizations on the package configuration that are not compatible with the product configurator flow. Please go to the <router-link class="link underline" :to="{
                name: 'SupplierProductPackagesView',
                params: {
                    productConfigurationId: $route.params.productConfigurationId,
                    supplierId: supplierId,
                }
            }">package configuration</router-link> for this product to change the details. You can also choose to <a class="link underline" @click="resetPackageConfiguration">reset</a> the configuration.</p>
        </div>
        <div v-else-if="availablePackages && availablePackages.length"  class="container">
            <div class="row">
                <div class="col-6">
                    <label>Facilities</label>
                    <div v-for="(availableFacility, availableFacilityKey) in availableFacilities"
                        :key="availableFacilityKey">
                        <mercur-checkbox v-model="tempPackageConfiguration.facilities" :value="availableFacility.locationId">
                            <strong>{{availableFacility.locationName}}</strong>
                        </mercur-checkbox>
                    </div>
                </div>
                <div class="col-6">
                    <label>Packages</label>
                    <pretty-select
                        :options="filteredAvailablePackages"
                        label="packageName"
                        placeholder="Select a default package"
                        :value="tempPackageConfiguration.configurations"
                        @input="selectPackage"
                    ></pretty-select>

                    <template v-if="tempPackageConfiguration.configurations">
                        <package-configuration
                            :is-edit="true"
                            :$v="$v.tempPackageConfiguration.configurations"
                            :package-configuration="tempPackageConfiguration.configurations"
                        ><span></span></package-configuration>
                    </template>

                </div>
            </div>
        </div>
        <div v-else-if="availablePackages === null" class="servicelevels-status">
            <em>loading packages...</em>
        </div>
        <div v-else class="servicelevels-status">
            <em class="error">No packages are available for this supplier.
                <router-link target="_blank" :to="packageConfigurationsViewRoute">Create packages</router-link>
                first and <a href="#" @click="getPackages">refresh</a>.</em>
        </div>
    </div>
</template>
<script>
import CONFIG from '@root/config'
import PackageConfiguration from '../packages/PackageConfiguration'
import PrettySelect from '../utils/PrettySelect'
import {
    minLength,
    required,
} from 'vuelidate/lib/validators'

export default {
    name: 'ProductPackageSelector',
    components: { PrettySelect, PackageConfiguration },
    props: {
        value: {
            default: null,
        },
        availableFacilities: {
            default: null,
        },
        availableAttributes: {
            default: null,
        },
    },
    data () {
        return {
            allFacilities: null,
            availablePackages: null,
            compatiblePackageConfiguration: null,
            tempPackageConfiguration: {
                attributes: this.getAllAttributes(),
                facilities: [],
                configurations: [],
            },
        }
    },

    computed: {
        packageConfigurationsViewRoute () {
            return {
                name: 'SupplierPackageConfigurations',
                params: {
                    supplierId: this.supplierId,
                },
            }
        },
        filteredAvailablePackages () {
            if (this.availablePackages === null) {
                return []
            }

            return this.availablePackages
        },
    },

    watch: {
        tempPackageConfiguration: {
            handler (tempPackageConfiguration) {
                if (this.compatiblePackageConfiguration === false) {
                    return
                }

                this.$emit('input', [{
                    attributes: this.getAllAttributes(),
                    facilities: tempPackageConfiguration.facilities,
                    configurations: [tempPackageConfiguration.configurations],
                }])
            },
            deep: true,
        },
    },

    validations: {
        tempPackageConfiguration: {
            facilities: {
                required,
                minLength: minLength(1),
            },
            configurations: CONFIG.VALIDATIONS.PACKAGES.PACKAGE_CONFIGURATION,
        },
    },

    methods: {
        areAttributeSetsMatching (attributeSetA, attributeSetB) {
            const a = JSON.stringify(this.sortAttributeSet(attributeSetA))
            const b = JSON.stringify(this.sortAttributeSet(attributeSetB))

            return a === b
        },
        sortAttributeSet (attributeSet) {
            const ordered = {}
            Object.keys(attributeSet).sort().forEach((key) => {
                ordered[key] = attributeSet[key].sort()
            })
            return ordered
        },
        getAllAttributes () {
            return this.availableAttributes.reduce((result, attribute) => {
                result[attribute.attributeKey] = attribute.options.map(option => option.option)
                return result
            }, {})
        },
        selectPackage (availablePackage) {
            this.$v.tempPackageConfiguration.configurations.$model = {
                ...availablePackage,
                packageType: null,
                maxWidth: null,
                weight: null,
                maxUnitsPerPackage: null,
                maxSurface: null,
                multiplier: 1,
            }
            this.$nextTick(() => {
                this.$v.tempPackageConfiguration.configurations.$reset()
            })
        },
        getPackages () {
            const url = CONFIG.API.ROUTES.SUPPLIERS.PACKAGES_CONFIGURATION.OVERVIEW.replace('{supplierId}', this.supplierId)
            this.addJob(url)

            this.$api.get(url).then(({ data }) => {
                this.availablePackages = data.data.items
            }).catch(() => {
                this.$root.$emit('notification:global', {
                    message: `Getting packages failed. Please try again.`,
                    type: 'error',
                })
            }).finally(() => {
                this.finishJob(url)
            })
        },
        resetPackageConfiguration () {
            this.$emit('input', [JSON.parse(JSON.stringify(this.tempPackageConfiguration))])
            this.compatiblePackageConfiguration = true
        },
    },

    created () {
        this.getPackages()
        let unwatchValue = () => {}

        unwatchValue = this.$watch('value', {
            handler (value) {
                if (value === null || this.availableAttributes === null) {
                    return
                }

                unwatchValue()

                if (!Array.isArray(value) || value.length === 0) {
                    this.$emit('input', [JSON.parse(JSON.stringify(this.tempPackageConfiguration))])
                    this.compatiblePackageConfiguration = true

                    return
                }
                if (Array.isArray(value) && value.length !== 1) {
                    this.compatiblePackageConfiguration = false

                    return
                }

                if (Object.values(value[0].attributes).length !== 0 && !this.areAttributeSetsMatching(value[0].attributes, this.getAllAttributes())) {
                    this.compatiblePackageConfiguration = false

                    return
                }

                const configuration = JSON.parse(JSON.stringify(value[0]))

                if (!Array.isArray(configuration.configurations)) {
                    return
                }

                this.$v.tempPackageConfiguration.$model = {
                    attributes: configuration.attributes,
                    facilities: configuration.facilities,
                    configurations: configuration.configurations[0],
                }
                this.compatiblePackageConfiguration = true
            },
            immediate: true,
        })
    },
}
</script>

<style lang="scss" scoped>
    .facility-selector {
        padding-right: 10px;
    }

    .facility-details {
        margin-bottom: 30px;
        border: 1px solid #dedede;
        padding: 0 10px 5px 10px;
        background-color: white;
    }

    .facility-title {
        border-top: 1px solid #dedede;
        border-bottom: 1px solid #dedede;
        background-color: white;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 10px;
        margin-left: -10px;
        margin-right: -10px;
        position: sticky;
        top: -20px;
        margin-top: -1px;
        z-index: 100;
    }

    .servicelevels-status {
        display: block;
        padding-top: 7px;
    }

    .servicelevel-checkboxes {
        flex: 1 0 100%;
    }

    .package-selection {
        margin-top: 10px;
    }
</style>
