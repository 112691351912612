<template>
    <div class="d-flex">
        <div class="col-3 px-1 fhf">
            <mercur-card class="fhf">
                <strong class="mb-2">Search</strong>
                <mercur-input v-model="filters.attribute"></mercur-input>
                <data-table
                    class="fill border"
                    :items="filteredAvailableAttributes"
                    :options="gridOptionsAvailableAttributes"
                    :quick-search="filters.attribute"
                    @update:selectedRows="setActiveAttribute"
                    ref="availableAttributesGrid"
                ></data-table>
            </mercur-card>
        </div>
        <div class="col-5 px-1 fhf">
            <mercur-card v-if="activeAttribute" class="mx-2 mb-4">
                <strong class="mb-2">{{activeAttribute.attributeName}}</strong>
                <div class="d-flex">
                    <div class="col-6 pl-1">
                        <mercur-select v-model="activePostfix">
                            <span slot="label">Postfix:</span>
                            <option class="italic" :value="''">none</option>
                            <option v-for="postfix in postfixes" :key="postfix" :value="postfix">{{postfix}}</option>
                        </mercur-select>
                    </div>
                    <div class="col-6 pr-1">
                        <mercur-input v-model="filters.option">
                            Search
                        </mercur-input>
                    </div>
                </div>
                <data-table
                    class="border"
                    :items="activeAttribute.options"
                    :options="gridOptionsSelectedAttributes"
                    :quick-search="filters.option"
                    @onRowSelected="attributeOptionSelectionChanged"
                    ref="selectedAttributeGrid"
                ></data-table>
            </mercur-card>
        </div>
        <div class="col-4 fhf">
            <mercur-card class="mr-2 mb-4">
                <div v-for="(attribute) in selectedAttributes" :key="attribute.attributeKey" class="summary-item">
                    <div class="summary-item__header"><span class="link" @click="setActiveAttribute([attribute])">{{attribute.attributeName}}</span>
                        <div class="summary-item__actions">
                            <mercur-button class="btn btn-icon" @click="setActiveAttribute([attribute])">
                                <i class="fas fa-edit text-grey" />
                            </mercur-button>
                            <mercur-button
                                v-if="canChangeAttributes"
                                class="btn btn-icon"
                                @click="removeAttribute(attribute)">
                                <i class="fas fa-trash text-grey" />
                            </mercur-button>
                        </div>
                    </div>

                    <mercur-simple-slide-up-down :duration="100">
                        <template slot="trigger" slot-scope="{ isActive }">
                            <small class="summary-item__trigger">{{ isActive ? 'Hide' : 'Show'}} options ({{getSelectedOptionsLength(attribute)}})</small>
                        </template>
                        <div class="summary-item__options">
                            {{parseSelectedAttributeOptions(attribute)}}
                        </div>
                    </mercur-simple-slide-up-down>
                    <div class="summary-item__empty-list" v-if="getSelectedOptionsLength(attribute) === 0">
                        <span class="text-grey">No options selected</span>
                    </div>
                </div>
                <mercur-select required v-model="selectedKeyAttribute" v-show="showKeyAttribute" class="mt-2">
                    <template slot="label">Key attribute</template>
                    <option v-for="(attribute, key) in selectedAttributes" :value="attribute.attributeKey" :key="key">{{attribute.attributeName}}</option>
                </mercur-select>
                <hr />
                <div>
                    Number of attributes:<br />
                    <strong>{{selectedAttributes.length}}</strong><br />
                    Number of variations:
                    <span class="pl-5">
                        <mercur-tooltip>
                            <i class="fas fa-question-circle text-grey" />
                            <span slot="label">Please keep in mind the blacklisted variations have<br> not been taken into account while calculating this number.</span>
                        </mercur-tooltip>
                    </span>
                    <br /><strong>{{numberOfVariations | asNumber}}</strong>
                </div>
            </mercur-card>
        </div>
        <mercur-dialog :is-open.sync="deleteConfirmationDialogActive">
            Are you sure you want to delete this attribute?
            <div slot="footer">
                <mercur-button class="btn" @click="deleteConfirmationDialogActive = false" :disabled="loading">Cancel</mercur-button>
                <mercur-button class="btn btn-primary" @click="executeDelete()" :disabled="loading">Delete</mercur-button>
            </div>
        </mercur-dialog>
    </div>
</template>

<script>
import CONFIG from '@root/config'
import DataTable from '../DataTable'
import AddAttributeOptionComponent from '@/components/products/AddAttributeOptionComponent'
import AddAttributeComponent from '@/components/products/AddAttributeComponent'

export default {
    name: 'ProductAttributeSelector',
    components: { DataTable },
    props: {
        attributes: {},
        previouslySelectedAttributes: {},
        formType: {},
        productConfigurationStatus: {
            default: 'DRAFT',
        },
        keyAttribute: null,
        showKeyAttribute: null,
    },

    data () {
        return {
            filters: {
                attribute: null,
                option: null,
            },
            loading: false,
            deleteConfirmationDialogActive: false,
            pendingAttributeToDelete: null,
            activeAttribute: null,
            attributeSearch: '',
            attributeSearchVisible: false,
            attributeOptionSearches: {},
            attributeValidations: null,
            gridOptionsAvailableAttributes: {
                quickSearchColumns: ['attributeName'],
                columns: {
                    'Attribute': {
                        field: 'attributeName',
                        sort: 'asc',
                        sortable: true,
                        checkboxSelection: true,
                        filterValueGetter: ({ data }) => `${data.attributeKey} ${data.attributeName}`,
                    },
                },
                rowSelection: 'single',
                pagination: false,
                frameworkComponents: {
                    AddAttributeComponent,
                },
                statusBar: {
                    statusPanels: [
                        {
                            statusPanel: 'AddAttributeComponent',
                            align: 'left',
                            statusPanelParams: (params) => {
                                return {
                                    hide: !this.canChangeAttributes,
                                    proposeAttributeAndOption: data => {
                                        this.proposeAttributeAndOption(data)
                                    },
                                }
                            },
                        },
                    ],
                },
            },
            gridOptionsSelectedAttributes: {
                columns: {
                    'Option': {
                        field: 'optionName',
                        checkboxSelection: true,
                        valueFormatter ({ value, data }) {
                            return `${value}${data.postfix ? data.postfix : ''}`
                        },
                        filterValueGetter: ({ data }) => `${data.option} ${data.optionName}`,
                    },
                    'Proposed': {
                        field: 'proposed',
                    },
                },
                rowSelection: 'multiple',
                pagination: false,
                quickSearchColumns: ['optionName'],
                frameworkComponents: {
                    AddAttributeOptionComponent,
                },
                statusBar: {
                    statusPanels: [
                        {
                            statusPanel: 'AddAttributeOptionComponent',
                            align: 'left',
                            statusPanelParams: (params) => {
                                return {
                                    hide: () => !this.canChangeAttributes,
                                    proposeAttributeOption: (attributeOption) => {
                                        this.proposeAttributeOption({
                                            attributeKey: this.activeAttribute.attributeKey,
                                            attributeOption,
                                        })
                                    },
                                    attributeName: () => this.activeAttribute.attributeKey,
                                    attributeValidations: () => this.attributeValidations,
                                }
                            },
                        },
                    ],
                },
                actions: [
                    {
                        icon: 'fas fa-trash',
                        text: 'Delete attribute',
                        onClick: ({ data }) => this.triggerDelete(data),
                        disabled: ({ data }) => data.selectedValue !== null,
                    },
                ],
            },
        }
    },

    watch: {
        postfixes () {
            try {
                this.$refs.selectedAttributeGrid.gridApi.redrawRows()
            } catch (e) {

            }
        },
        activeAttribute (value) {
            if (!value) {
                return
            }
            this.$refs.availableAttributesGrid.gridApi.forEachNode(node => {
                node.setSelected(node.data.attributeKey === value.attributeKey)
            })
        },
    },

    computed: {
        activePostfix: {
            get () {
                const selectedOption = this.activeAttribute.options.find(option => option.selectedValue)
                if (selectedOption) {
                    return selectedOption.postfix
                }
                return ''
            },
            set (value) {
                this.activeAttribute.options.forEach(attributeOption => {
                    attributeOption.postfix = value
                })
                this.$refs.selectedAttributeGrid.gridApi.redrawRows()
            },
        },
        canChangeAttributes () {
            return this.formType === 'new' || this.productConfigurationStatus === 'DRAFT'
        },
        postfixes () {
            return this.$store.state.productAttributes.postfixes
        },
        filteredAvailableAttributes () {
            const q = this.attributeSearch.toLowerCase()
            return this.attributes
                .filter((attribute) => {
                    return attribute.attributeKey.toLowerCase().includes(q) || attribute.attributeName.toLowerCase().includes(q)
                })
                .sort((a, b) => ('' + a.attributeKey).localeCompare('' + b.attributeKey))
        },
        selectedAttributes () {
            return this.attributes.filter((a) => a.selected).sort((a, b) => b.attributeKey - a.attributeKey)
        },
        canProceed () {
            return this.selectedAttributes.find(attribute => attribute.options.find(option => option.selectedValue !== null))
        },
        numberOfVariations () {
            let count = 0
            this.selectedAttributes.forEach(attribute => {
                if (!attribute || !attribute.options || !attribute.options.length) {
                    return
                }

                const selectedOptions = attribute.options.filter(option => option.selectedValue).length

                if (selectedOptions > 0) {
                    count = (count || 1) * selectedOptions
                }
            })

            return count
        },
        selectedKeyAttribute: {
            get () {
                return this.keyAttribute
            },
            set (value) {
                this.$emit('selection-change', value)
            },
        },
    },
    methods: {
        triggerDelete (data) {
            this.deleteConfirmationDialogActive = true
            this.pendingAttributeToDelete = data
        },
        executeDelete () {
            if (this.pendingAttributeToDelete !== null) {
                this.loading = true

                const payload = {
                    attributeId: this.pendingAttributeToDelete.attributeId,
                    attributeName: this.activeAttribute.attributeKey,
                    attributeOption: this.pendingAttributeToDelete.option,
                }

                const url = CONFIG.API.ROUTES.PRODUCTS.GENERATION.ATTRIBUTES.DELETE_OPTION
                this.addJob(url)
                this.$api.post(url, payload).then(() => {
                    const deleteIndex = this.activeAttribute.options.findIndex(item => item.attributeId === this.pendingAttributeToDelete.attributeId)
                    this.activeAttribute.options.splice(deleteIndex, 1)
                    this.updateAttributeSelectionGrid()
                    this.$root.$emit('notification:global', {
                        message: `Attribute successfully deleted.`,
                        type: 'success',
                    })
                }).catch(({ data }) => {
                    this.$root.$emit('notification:global', {
                        message: data.exceptions[0].error,
                        type: 'error',
                    })
                }).finally(() => {
                    this.loading = false
                    this.deleteConfirmationDialogActive = false
                    this.pendingAttributeToDelete = null
                    this.finishJob(url)
                })
            }
        },
        getSelectedOptionsLength (attribute) {
            return attribute.options.filter(o => o.selectedValue).length
        },
        removeAttribute (attribute) {
            attribute.selected = null
            this.selectedAttribute = null
            if (this.keyAttribute === attribute.attributeKey) {
                this.$emit('selection-change', null)
            }
        },
        parseSelectedAttributeOptions (attribute) {
            return attribute.options.filter(option => option.selectedValue).map(attributeOption => {
                return this.$options.filters.beautify(`${attributeOption.option}${attributeOption.postfix ? attributeOption.postfix : ''}`)
            }).join(', ')
        },
        attributeOptionSelectionChanged ({ data, node }) {
            const selectedOption = this.activeAttribute.options.find(attributeOption => attributeOption.option === data.option)
            this.$set(selectedOption, 'selectedValue', node.isSelected() ? (new Date()).getTime() : null)

            if (this.canChangeAttributes) {
                this.$set(this.activeAttribute, 'selected', this.activeAttribute.options.some(attributeOption => attributeOption.selectedValue))
            }
        },
        setActiveAttribute (selection) {
            this.activeAttribute = selection[0]
            this.filters.option = null

            this.updateAttributeSelectionGrid()
        },
        updateAttributeSelectionGrid () {
            this.$nextTick(() => {
                if (!this.$refs.selectedAttributeGrid) {
                    return
                }
                this.$refs.selectedAttributeGrid.gridApi.forEachNode(node => {
                    node.setSelected(node.data.selectedValue !== null)
                })
            })
        },
        proposeAttributeAndOption ({ attributeKey, attributeOption, postfix }) {
            let foundAttribute = this.attributes.find(attribute => attribute.attributeKey === attributeKey)
            if (!foundAttribute) {
                foundAttribute = {
                    attributeKey,
                    attributeName: this.$options.filters.beautify(attributeKey),
                    selected: true,
                    options: [],
                }
                this.attributes.push(foundAttribute)
            }

            foundAttribute.options.push({
                attributeKey,
                option: attributeOption,
                optionName: this.$options.filters.beautify(attributeOption),
                postfix,
                selectedValue: attributeOption,
                configuration: [],
                additionalData: [],
                proposed: true,
            })

            this.$refs.availableAttributesGrid.gridApi.redrawRows()
        },
        proposeAttributeOption ({ attributeKey, attributeOption }) {
            let foundAttribute = this.selectedAttributes.find(attribute => attribute.attributeKey === attributeKey)
            if (!foundAttribute) {
                foundAttribute = this.attributes.find(attribute => attribute.attributeKey === attributeKey)
                foundAttribute.selected = true
            }

            foundAttribute.options.push({
                attributeKey,
                option: attributeOption,
                optionName: this.$options.filters.beautify(attributeOption),
                postfix: '',
                selectedValue: attributeOption,
                configuration: [],
                additionalData: [],
                proposed: true,
            })
            this.$refs.selectedAttributeGrid.gridApi.redrawRows()
            this.updateAttributeSelectionGrid()
        },

        finishStep () {
            const cleanAttributesWithOptions = JSON.parse(JSON.stringify(this.selectedAttributes)).filter((attribute, key) => {
                attribute.options = attribute.options.filter(option => option.selectedValue)
                return attribute
            }).filter(attribute => attribute.options.length)

            this.$emit('nextStep', {
                selectedAttributesWithOptions: cleanAttributesWithOptions,
            })
        },
    },
    created () {
        this.$api.get(CONFIG.API.ROUTES.PRODUCTS.GENERATION.ATTRIBUTES.VALIDATIONS.LIST).then(({ data }) => {
            this.$set(this, 'attributeValidations', Object.fromEntries(data.data.map(validationRule => {
                return [validationRule.attributeName, validationRule]
            })))
        })
    },
}
</script>

<style lang="scss" scoped>
    .summary-list {
        overflow-y: scroll;
        padding-right: 5px;
    }
    .summary-item {
        border-bottom: 1px solid #dedede;
        padding-bottom: 10px;
        &__header {
            padding-top: 10px;
            display: flex;
            justify-content: space-between;
            font-weight: 600;
        }
        &__trigger {
            opacity: 0.7;
        }
    }

</style>
