<template>
    <div v-if="params && !params.hide">
        <mercur-button class="btn btn-yellow" @click="promptActive = true">Add</mercur-button>
        <mercur-dialog :is-open.sync="promptActive" >
            <h2 class="font-weight-normal mt-1">Add attribute and option</h2>
            <form @submit.prevent="proposeAttributeAndOptionSubmit" class="pt-20">
                <div>
                    <div>
                        <mercur-input v-model="proposedAttribute">Attribute</mercur-input>
                    </div>
                    <div>
                        <mercur-input v-model="proposedAttributeOption">Option</mercur-input>
                    </div>
                    <div>
                        <label>Postfix:</label>
                        <mercur-select v-model="proposedAttributeOptionPostfix">
                            <option class="italic" :value="''">none</option>
                            <option v-for="postfix in postfixes" :key="postfix" :value="postfix">{{postfix}}</option>
                        </mercur-select>
                    </div>
                </div>
                <div class="align-right">
                    <mercur-button type="submit" class="btn btn-yellow btn-raised">Add</mercur-button>
                </div>
            </form>
        </mercur-dialog>
    </div>
</template>
<script>

import Vue from 'vue'
import { mapState } from 'vuex'

export default Vue.extend({
    name: 'AddAttributeComponent',
    data () {
        return {
            proposedAttribute: null,
            proposedAttributeOption: null,
            proposedAttributeOptionPostfix: null,
            promptActive: false,
        }
    },
    methods: {
        proposeAttributeAndOptionSubmit () {
            this.params.proposeAttributeAndOption({
                attributeKey: JSON.parse(JSON.stringify(this.proposedAttribute)),
                attributeOption: JSON.parse(JSON.stringify(this.proposedAttributeOption)),
                postfix: JSON.parse(JSON.stringify(this.proposedAttributeOptionPostfix)),
            })
            this.proposedAttribute = null
            this.proposedAttributeOption = null
            this.proposedAttributeOptionPostfix = null
        },
    },
    computed: {
        ...mapState('productAttributes', ['postfixes']),
    },
    created () {
        this.$store.dispatch('productAttributes/fetchPostfixes')
    },
})
</script>
