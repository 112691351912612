<template>
    <div v-if="configurations">
        <p v-if="configurations.length === 0">
            Please add a cutoff configuration
        </p>
        <div v-for="(configuration, configurationKey) in v.$each.$iter" :key="configurationKey">
            <mercur-card class="mx-4">
                <div class="container">
                    <div class="row">
                        <div v-if="configuration && selectedFacilities" class="col-6 mb-4">
                            <div>Facilities</div>
                            <span v-for="facility in selectedFacilities" :key="facility.facilityId">
                                <mercur-checkbox v-model="v.$each[configurationKey].facilityIds.$model" :value="facility.locationId">{{facility.locationName}}</mercur-checkbox>
                            </span>
                            <div class="error" v-if="configuration.facilityIds.$dirty && !configuration.facilityIds.required">Required</div>
                        </div>
                        <div class="col-6 mb-4">
                            <div>Production days</div>
                            <span v-for="(productionDay, key) in availableProductionDays" :key="key">
                                <mercur-checkbox v-model="v.$each[configurationKey].productionDays.$model" :value="productionDay">{{productionDay}}</mercur-checkbox>
                            </span>
                            <div class="error" v-if="configuration.productionDays.$dirty && !configuration.productionDays.required">Required</div>
                        </div>
                        <div class="col-6 mb-4">
                            <div>Countries</div>
                            <span class="mr-3" v-for="(countryCode, key) in selectedCountries" :key="key">
                                <mercur-checkbox class="mb-2" v-model="v.$each[configurationKey].countries.$model" :value="countryCode">{{countryCode}}</mercur-checkbox>
                            </span>
                            <div class="error" v-if="configuration.countries.$dirty && !configuration.countries.required">Required</div>
                        </div>
                        <div class="col-6 mb-4">
                            <div>Cutoff time</div>
                            <span class="mr-3" v-for="(dayName, key) in daysOfWeek" :key="key">
                                <mercur-checkbox v-model="v.$each[configurationKey].cutoffTime.daysOfWeek.$model" :value="key">{{dayName}}</mercur-checkbox>
                            </span>
                            <div class="error" v-if="configuration.cutoffTime.daysOfWeek.$dirty && !configuration.cutoffTime.daysOfWeek.required">Required</div>
                            <mercur-input class="mt-4" v-model="v.$each[configurationKey].cutoffTime.time.$model" type="time">
                                Time
                                <template slot="note">
                                    <div class="error" v-if="configuration.cutoffTime.time.$dirty && !configuration.cutoffTime.time.required">Required</div>
                                </template>
                            </mercur-input>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="mb-2">Attributes</div>
                            <div class="attribute-selection__labels">
                                <mercur-radio v-model="configurations[configurationKey].allAttributes" :value="true">All selected</mercur-radio>
                                <mercur-radio v-model="configurations[configurationKey].allAttributes" :value="false">Custom</mercur-radio>
                            </div>
                            <div class="attribute-selection__finetune" v-if="configurations[configurationKey].allAttributes === false">
                                <div v-for="attribute in selectedAttributes" :key="attribute.attributeKey" class="attribute-selection__attribute full-height-layout">
                                    <div>
                                        {{attribute.attributeName}}<br/>
                                        <a class="link" @click="configurations[configurationKey].attributes[attribute.attributeKey] = getToggleSelectAll(configurationKey, attribute.attributeKey)">select&nbsp;all/none</a>
                                    </div>
                                    <div class="attribute-selection__attribute-options fill">
                                        <div v-for="attributeOption in attribute.options" :key="attributeOption.selectedValue">
                                            <mercur-checkbox
                                                v-model="configurations[configurationKey].attributes[attribute.attributeKey]"
                                                :value="attributeOption.selectedValue"
                                            >
                                                {{attributeOption.selectedValue}}{{attributeOption.postfix}}
                                            </mercur-checkbox>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-right">
                    <mercur-button class="btn btn-red" @click="configurations.splice(configurationKey, 1)">Remove</mercur-button>
                </div>
            </mercur-card>
        </div>
        <div class="text-right my-4 mr-4">
            <mercur-button class="btn btn-yellow" @click="addConfiguration">
                <span>Add extra configuration</span>
                <i class="fas fa-plus" />
            </mercur-button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProductCutoffTimesConfigurations',
    props: {
        loading: {
            type: Boolean,
        },
        selectedAttributes: {},
        selectedFacilities: {},
        cutoffTimeConfigurations: {},
        v: {},
        autoSelectFirst: {
            default: false,
            type: Boolean,
        },
    },
    data () {
        return {
            configurations: null,
            daysOfWeek: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],

        }
    },
    computed: {
        availableProductionDays () {
            const productionDays = []
            if (!this.selectedFacilities) {
                return productionDays
            }

            this.selectedFacilities.forEach(facility => {
                if (!facility.productionDays) {
                    return
                }

                Object.values(facility.productionDays).forEach(productionDaysSetting => {
                    productionDays.push(parseFloat(productionDaysSetting.days))
                })
            })

            return [...new Set(productionDays)]
        },
        selectedCountries () {
            const countries = []
            if (!this.selectedFacilities) {
                return countries
            }

            this.selectedFacilities.forEach(facility => {
                if (!facility.serviceLevels) {
                    return
                }

                countries.push(...facility.serviceLevels.flatMap(serviceLevel => Object.values(serviceLevel.countriesTo || {})))
            })

            return [...new Set(countries)]
        },
        configurationList () {
            return this.configurations.map(configuration => {
                return {
                    facilities: configuration.facilityIds.map(facilityId => this.selectedFacilities.find(facility => facilityId === facility.facilityId).locationName),
                    productionDays: configuration.productionDays,
                    countries: configuration.countries,
                    cutOffTime: `${configuration.cutoffTime.time} on ${configuration.cutoffTime.daysOfWeek.map(day => this.daysOfWeek[day]).join(', ')}`,
                    attributes: configuration.allSelected ? 'All' : Object.entries(configuration.attributes).map(([attributeOptions, attributeName]) => attributeName),
                }
            })
        },
    },
    methods: {
        valueChange () {
            this.$emit('update:cutoffTimeConfigurations', this.configurations)
        },

        getToggleSelectAll (configurationKey, attributeKey) {
            if (this.configurations[configurationKey].attributes[attributeKey].length === 0) {
                return this.selectedAttributes.find(attribute => attribute.attributeKey === attributeKey).options.flatMap(option => option.selectedValue)
            }

            return []
        },

        addConfiguration (selectAll = false) {
            const attributes = {}
            this.selectedAttributes.forEach(attribute => {
                this.$set(attributes, attribute.attributeKey, [])
            })

            this.configurations.push({
                countries: selectAll ? this.selectedCountries : [],
                productionDays: selectAll ? JSON.parse(JSON.stringify(this.availableProductionDays)) : [],
                facilityIds: selectAll ? this.selectedFacilities.map(e => e.locationId) : [],
                cutoffTime: {
                    time: '11:30',
                    daysOfWeek: [1, 2, 3, 4, 5],
                },
                attributes,
                allAttributes: true,
            })
        },
    },
    created () {
        this.$watch(() => this.cutoffTimeConfigurations, () => {
            if (Array.isArray(this.cutoffTimeConfigurations)) {
                this.cutoffTimeConfigurations.forEach(configuration => {
                    if (configuration.productionDays) {
                        configuration.productionDays = Object.values(configuration.productionDays)
                    }
                })
            }
            this.configurations = this.cutoffTimeConfigurations
            this.$watch(() => this.configurations, () => {
                this.valueChange()
            })
        }, {
            immediate: true,
        })

        if (this.autoSelectFirst && !this.configurations.length) {
            this.addConfiguration(true)
        }
    },
}
</script>

<style lang="scss" scoped>

    .attribute-selection {
        margin-top: 20px;
        max-width: 100%;
        overflow: auto;

        &__finetune {
            height: 340px;
            display: flex;
            justify-content: space-between;
        }

        &__attribute {
            padding-left: 5px;
            padding-right: 5px;
            height: 100%;
        }
    }
    .link {
        cursor: pointer;
        font-size: 10px;
    }
</style>
