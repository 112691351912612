<template>
    <div>
        <div class="d-flex">
            <div class="attribute-list-blacklist col-12" v-for="(attribute, key) in selectedAttributesFiltered" :key="key" v-show="selectedAttributesFiltered[key].options.length > 1">
                <attribute-block :scrollable="true">
                    <template slot="header"><h3 class="my-0 font-weight-normal text-white">{{attribute.attributeName}}</h3></template>
                    <div class="px-3 py-2" v-for="(attributeOption, optionKey) in attribute.options" :key="optionKey">
                        <mercur-checkbox v-model="blacklistSet" :value="attributeOption" :disabled="false && checkBlacklistExists(attribute.attributeKey, attributeOption)">
                            {{attributeOption.selectedValue | beautify}}{{attributeOption.postfix}}
                        </mercur-checkbox>
                    </div>
                </attribute-block>
            </div>
        </div>
        <div class="mt-4 container">
            <div class="row">
                <div class="col-6">
                    <attribute-block type="primary" :disabled="!hasBlacklistSetPending">
                        <template slot="header"><h3 class="my-0 font-weight-normal text-white">Pending blacklist</h3></template>
                        <transition :name="confirmBlacklistSetTransition">
                            <div v-if="hasBlacklistSetPending">
                                <ul>
                                    <li v-for="(attribute, attributeKey) in blacklistSetFiltered" :key="attributeKey">
                                        <strong>{{attribute.attributeName}}:</strong>
                                        <span v-for="(value, index) in attribute.values" :key="index">{{ value }}, </span>
                                    </li>
                                </ul>
                                <div class="text-right">
                                    <mercur-button class="btn btn-primary mb-2 mr-2" :disabled="!hasBlacklistSetPending" @click="confirmBlacklistSet">
                                        <i class="fas fa-plus text-white" />
                                        <span>add to blacklist</span>
                                    </mercur-button>
                                </div>
                            </div>
                        </transition>
                    </attribute-block>
                </div>
                <div class="col-6">
                    <attribute-block type="primary">
                        <template slot="header"><h3 class="my-0 font-weight-normal text-white">Blacklist</h3></template>
                        <template v-if="blacklistIsOpen">
                            <transition-group name="slide-fade-blacklists" :mode="selectedBlacklists.length ? 'out-in' : 'in-out'" tag="div">
                                <div class="d-flex justify-content-between align-items-center blacklist-item" v-for="(blacklistItem, blacklistKey) in selectedBlacklists" :key="blacklistItem.key">
                                    <ul>
                                        <li v-for="(attribute, attributeKey) in blacklistItem.attributes" :key="attributeKey">
                                            <strong>{{attribute.attributeName}}:</strong>
                                            <template v-if="attribute.values">
                                                <span v-for="(value, index) in attribute.values" :key="index">{{ value }}, </span>
                                            </template>
                                            <template v-else>{{ attribute.value }}</template>
                                        </li>
                                    </ul>
                                    <mercur-button class="btn btn-icon mr-2" @click="removeBlacklistItem(blacklistKey)">
                                        <i class="fas fa-trash" />
                                    </mercur-button>
                                </div>
                                <div class="p-2 text-grey" v-if="selectedBlacklists.length === 0" key="empty-state">Blacklist is empty</div>
                            </transition-group>
                        </template>
                        <template v-else>
                            <div class="align-center">{{selectedBlacklists.length}} blacklist sets</div>
                            <mercur-button class="btn btn-primary" @click="blacklistIsOpen = true">Show</mercur-button>
                        </template>
                    </attribute-block>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import collect from 'collect.js'
import AttributeBlock from '@/components/AttributeBlock'

export default {
    name: 'AdvancedProductAttributeBlacklistSelector',
    props: ['selectedAttributes', 'selectedBlacklists', 'previouslySelectedBlacklists'],
    components: { AttributeBlock },

    data () {
        return {
            blacklistSet: [],
            confirmBlacklistSetTransition: '',
            blacklistIsOpen: true,
        }
    },
    computed: {
        hasBlacklistSetPending () {
            return this.blacklistSetFiltered.length > 0 && this.existsInBlacklists(this.blacklistSet) === false
        },

        blacklistSetFiltered () {
            const arr = []
            this.blacklistSet.forEach((item) => {
                if (arr.map(e => e.attributeKey).includes(item.attributeKey)) {
                    const index = arr.findIndex(e => e.attributeKey === item.attributeKey)
                    arr[index].values.push(this.$options.filters.beautify(item.selectedValue))
                } else {
                    arr.push(
                        {
                            attributeName: this.selectedAttributes.find(attribute => attribute.attributeKey === item.attributeKey).attributeName,
                            attributeKey: item.attributeKey,
                            values: [this.$options.filters.beautify(item.selectedValue)],
                        }
                    )
                }
            })
            return arr.filter((a) => a).sort((a, b) => {
                const indexA = this.selectedAttributes.findIndex(attribute => attribute.attributeKey === a.attributeKey)
                const indexB = this.selectedAttributes.findIndex(attribute => attribute.attributeKey === b.attributeKey)
                return indexB - indexA
            })
        },

        selectedAttributesFiltered () {
            const attributes = JSON.parse(JSON.stringify(this.selectedAttributes))
            attributes.forEach((attribute) => {
                attribute.options.forEach((option) => {
                    option.attributeKey = attribute.attributeKey
                })
            })

            return attributes
        },
    },
    watch: {
        selectedBlacklists (selectedBlacklists) {
            if (selectedBlacklists.length > 10) {
                this.blacklistIsOpen = false
            }
        },
    },
    methods: {
        removeBlacklistItem (blacklistKey) {
            this.selectedBlacklists = this.selectedBlacklists.filter((item, key) => key !== blacklistKey)
            this.$emit('update:selectedBlacklists', this.selectedBlacklists)
        },
        confirmBlacklistSet () {
            this.confirmBlacklistSetTransition = 'slide-fade-blacklistset'
            this.$nextTick(() => {
                const rawBlacklistSet = JSON.parse(JSON.stringify(this.blacklistSet))
                const newBlacklistItem = collect(rawBlacklistSet).map((value) => {
                    return {
                        attributeName: this.selectedAttributes.find(attribute => attribute.attributeKey === value.attributeKey).attributeName,
                        values: [this.$options.filters.beautify(value.selectedValue)],
                    }
                }).toArray()
                const refinedItem = []

                newBlacklistItem.forEach((item) => {
                    if (refinedItem.map((e) => e.attributeName).includes(item.attributeName)) {
                        const index = refinedItem.findIndex(e => e.attributeName === item.attributeName)
                        refinedItem[index].values.push(...item.values)
                    } else {
                        refinedItem.push(item)
                    }
                })

                this.selectedBlacklists.unshift({
                    attributes: refinedItem,
                    raw: rawBlacklistSet,
                    key: new Date().getTime(),
                })
                this.blacklistSet = []
                this.confirmBlacklistSetTransition = ''
            })
        },
        checkBlacklistExists (attributeKey, attributeValue) {
            let tempBlacklistSet = Object.assign({}, this.blacklistSet)
            tempBlacklistSet[attributeKey] = attributeValue

            return this.existsInBlacklists(tempBlacklistSet)
        },
        existsInBlacklists (item) {
            const itemString = JSON.stringify(item)

            return typeof this.selectedBlacklists.find(blacklistItem => {
                return JSON.stringify(blacklistItem.raw) === itemString
            }) !== 'undefined'
        },

        finishStep () {
            this.$emit('nextStep', {
                selectedBlacklists: this.selectedBlacklists,
            })
        },
        handlePreviouslySetBlacklists () {
            if (this.selectedAttributes.length === 0) {
                this.blacklistIsOpen = true
                return
            }

            this.previouslySelectedBlacklists.forEach((blacklistSet, key) => {
                const rawBlacklistSet = collect(blacklistSet).map((value) => {
                    return {
                        option: value,
                        selectedValue: value,
                    }
                }).all()

                const newBlacklistItem = collect(blacklistSet).filter((item, key) => key !== 'hashId').map((value, attributeKey) => {
                    const attribute = this.selectedAttributes.find(attribute => attribute.attributeKey === attributeKey)
                    if (!attribute) {
                        return
                    }

                    return {
                        attributeName: attribute.attributeName,
                        value: this.$options.filters.beautify(value),
                    }
                }).toArray()

                if (newBlacklistItem.every((item) => item)) {
                    this.blacklists.unshift({
                        attributes: newBlacklistItem,
                        raw: rawBlacklistSet,
                        key: new Date().getTime() + key,
                    })
                }
            })
        },
    },
}
</script>
<style lang="scss" scoped>
    $transition-time: .2s;
    .blacklist-item {
        border-top: 1px solid #f1f1f1;
    }

    .attribute-list-blacklist {
        display: flex;
        flex-direction: column;
        margin-right: 5px;
        margin-left: 5px;
        max-width: 350px;

    }

    .slide-fade-blacklistset-enter-active {
        transition: all $transition-time;
    }
    .slide-fade-blacklistset-leave-active {
        transition: all $transition-time;
    }
    .slide-fade-blacklistset-enter {
        transform: translateY(-100px);
        opacity: 0;
    }
    .slide-fade-blacklistset-leave-to {
        transform: translateX(100px);
        opacity: 0;
    }

    .slide-fade-blacklists-enter-active {
        transition: all $transition-time;
    }
    .slide-fade-blacklists-leave-active.empty-state {
        transition: all $transition-time;
        position: absolute;
    }
    .slide-fade-blacklists-leave-active:not(.empty-state) {
        transition: all $transition-time;
    }
    .slide-fade-blacklists-enter:not(.empty-state) {
        transform: translateX(-100px);
        opacity: 0;
    }
    .slide-fade-blacklists-enter.empty-state {
        opacity: 0;
        max-height: 0;
    }
    .slide-fade-blacklists-leave-to {
        opacity: 0;
    }
    .slide-fade-blacklists-leave-to:not(.empty-state) {
        transform: translateY(-30px);
    }
</style>
