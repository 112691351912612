<template>
    <div class="mb-3">
        <mercur-card class="mx-3 mb-3">
            <div class="d-flex justify-content-between">
                <h2 class="font-weight-normal">{{packageConfiguration.packageName}}</h2>
                <slot name="action">
                    <mercur-button
                        v-if="isAllowedTo('SupplierCentral/createPackageConfiguration')"
                        @click="openEditPackageConfigurationDialog(packageConfiguration)"
                    >
                        <i class="fas fa-pen"></i>
                    </mercur-button>
                </slot>
            </div>

            <div class="package-configuration__details">
                <div>
                    <strong>Dimensions: </strong>
                    <span>{{packageConfiguration.packageWidth}}&times;{{packageConfiguration.packageLength}}&times;{{packageConfiguration.packageHeight}}mm (W&times;L&times;H)</span>
                </div>
                <div>
                    <strong>Volume: </strong>
                    <span>{{getVolumeForDimensions(packageConfiguration) / 1000}}cm&sup3;</span>
                </div>
                <div>
                    <strong>Tare weight: </strong>
                    <span>{{packageConfiguration.weightTare}}g</span>
                </div>
                <template v-if="isEdit">

                    <mercur-select class="mt-2" v-model="$v.packageType.$model">
                        <label slot="label">Package type</label>
                        <option
                            v-for="(packageType, key) in packageTypes"
                            :key="key"
                            :value="packageType.value"
                        >{{packageType.title}}</option>
                    </mercur-select>
                    <span class="error" v-if="$v.packageType.$invalid">Not valid</span>

                    <div class="d-flex">
                        <div>
                            <mercur-input :class="{'error': $v.weight.$error}" v-model.number="$v.weight.$model" type="number" required>
                                Weight (kilogrammes)
                                <template slot="note"><span class="error" v-if="$v.weight.$invalid">Not valid</span></template>
                            </mercur-input>
                        </div>
                        <div class="mx-2 mt-4 pt-2">
                            <span>/</span>
                        </div>
                        <div>
                            <mercur-input :class="{'error': $v.multiplier.$error}" v-model.number="$v.multiplier.$model" type="number" required>
                                Multiplier
                                <template slot="note"><span class="error" v-if="$v.multiplier.$invalid">Not valid</span></template>
                            </mercur-input>
                        </div>
                        <div class="ml-2 mt-4 pt-2">
                            <mercur-tooltip>
                                <template slot="label">
                                    You can use the multiplier field to specify very small weights.<br>
                                    For example: if 1000 flyers weigh 2 kilogramme, enter <u>2</u> in the <em>Weight</em> field and <u>1000</u> in the <em>Multiplier</em> field
                                </template>
                                <i class="fas fa-question-circle" />
                            </mercur-tooltip>
                        </div>
                    </div>

                    <mercur-input :class="{'error': $v.maxUnitsPerPackage.$error}" v-model.number="$v.maxUnitsPerPackage.$model" type="number" required>
                        Max units per package
                        <template slot="note">
                            <span class="error" v-if="$v.maxUnitsPerPackage.$invalid">Not valid</span>
                        </template>
                    </mercur-input>

                    <mercur-input
                        v-if="packageConfiguration.packageType === 'ROLLABLE'"
                        :class="{'error': $v.maxWidth.$error}"
                        v-model.number="$v.maxWidth.$model"
                        type="number"
                        required>
                        Max width (mm)
                        <template slot="note">
                            <span class="error" v-if="$v.maxWidth.$invalid">Not valid</span>
                        </template>
                    </mercur-input>

                    <mercur-input
                        v-if="packageConfiguration.packageType === 'NON_FOLDABLE' || packageConfiguration.packageType === 'FOLDABLE'"
                        :class="{'error': $v.maxSurface.$error}"
                        v-model.number="$v.maxSurface.$model"
                        type="number"
                        required>
                        Max width (mm)
                        <template slot="note">
                            <span class="error" v-if="$v.maxSurface.$invalid">Not valid</span>
                        </template>
                    </mercur-input>

                </template>
                <slot v-else name="content"></slot>
            </div>
        </mercur-card>
    </div>
</template>

<script>
import CONFIG from '@root/config'
export default {
    name: 'PackageConfiguration',
    props: {
        packageConfiguration: {},
        isEdit: {
            default: false,
        },
        $v: {
            default: () => ({}),
        },
    },
    data () {
        return {
            packageTypes: CONFIG.PACKAGES.TYPES,
        }
    },

    methods: {
        getVolumeForDimensions (packageConfiguration) {
            return packageConfiguration.packageWidth * packageConfiguration.packageHeight * packageConfiguration.packageLength
        },
        openEditPackageConfigurationDialog (packageConfiguration) {
            this.$emit('editPackageConfiguration', packageConfiguration)
        },
    },
}
</script>

<style scoped lang="scss">
.package-configuration {
    &__details {
        color: #555;
    }
}
.tooltip__label {
    z-index: 10000;
}

</style>
