<template>
    <div class="container">
        <h3>Confirmation</h3>
        <div class="row">
            <div class="col-6">
                <mercur-card class="mb-4">
                    <div class="d-flex justify-content-between">
                        <h2 class="mt-1 font-weight-normal">Product name</h2>
                        <mercur-button class="btn" :to="getUrlForStep('general')">Change</mercur-button>
                    </div>
                    <div>
                        {{productConfigurationName}}
                        <template v-if="productCategory"><br><span>Product Category: {{productCategory}}</span></template>
                    </div>
                </mercur-card>
            </div>
            <div class="col-6">
                <mercur-card class="mb-4">
                    <div class="d-flex justify-content-between">
                        <h2 class="mt-1 font-weight-normal">Facilities</h2>
                        <mercur-button class="btn" :to="getUrlForStep('facilities')">Change</mercur-button>
                    </div>
                    <div>
                        <ul>
                            <li v-for="facility in selectedFacilities" :key="facility.locationId">
                                <strong>{{facility.locationName}}</strong><br />
                                <span v-if="facility.productionDays">Production days: {{facility.productionDays.flatMap(({days}) => days).join(', ')}}</span>
                            </li>
                        </ul>
                    </div>
                </mercur-card>
            </div>
            <div class="col-6">
                <mercur-card class="mb-4">
                    <h2 class="mt-1 font-weight-normal">Number of variations</h2>
                    <div>Without blacklist filtering</div>
                    <div>
                        {{totalNumberOfVariations}} variations
                    </div>
                </mercur-card>
            </div>
            <div class="col-6">
                <mercur-card class="mb-4">
                    <div class="d-flex justify-content-between">
                        <h2 class="mt-1 font-weight-normal">Attributes</h2>
                        <mercur-button class="btn" :to="getUrlForStep('attributes')">Change</mercur-button>
                    </div>
                    <div>
                        <span class="validity">Validity: </span>
                        <span v-if="checkingValidity">
                            checking...
                            <mercur-spinner />
                        </span>
                        <status-chip v-else-if="!validityErrors || validityErrors.length === 0">VALID </status-chip>
                        <div v-else>Not valid:
                            <p v-for="(validityError, key) in validityErrors" :key="key">
                                {{ validityError }}
                            </p>
                        </div>
                        <p v-if="configurationList && configurationList.length">Notice: the selected attributes are also found in these products: <strong>{{ configurationListProductNames.join(', ') }}</strong></p>
                    </div>

                    <div>
                        <ul v-for="(selectedAttribute, selectedAttributeKey) in selectedAttributes" :key="selectedAttributeKey">
                            <li>
                                <strong>{{selectedAttribute.attributeName}}</strong>
                                <ul>
                                    <li class="selected-option" v-for="(selectedOption, optionKey) in selectedAttribute.options" :key="optionKey">
                                        {{beautifyValue(selectedOption.selectedValue)}}{{selectedOption.postfix}}
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </mercur-card>
            </div>
            <div class="col-6">
                <mercur-card class="mb-4">
                    <div class="d-flex justify-content-between">
                        <h2 class="mt-1 font-weight-normal">Blacklists</h2>
                        <mercur-button class="btn" :to="getUrlForStep('blacklists')">Change</mercur-button>
                    </div>
                    <div>
                        <p v-if="selectedBlacklists.length > 10 || selectedBlacklists.length === 0">
                            {{selectedBlacklists.length}} blacklist combinations
                        </p>
                        <ul v-else>
                            <li v-for="(selectedBlacklist, selectedBlacklistKey) in selectedBlacklists" :key="selectedBlacklistKey">
                                <div class="selected-option" v-for="(selectedOption, optionKey) in selectedBlacklist.attributes" :key="optionKey">
                                    {{selectedOption.attributeName}}:
                                    <template v-if="selectedOption.values">
                                      <span v-for="(value, index) in selectedOption.values" :key="index">{{ value }}, </span>
                                    </template>
                                    <template v-else>{{ selectedOption.value }}</template>
                                </div>
                            </li>
                        </ul>
                    </div>
                </mercur-card>
            </div>
        </div>

        <portal to="actionbar" target-class="fixed-bottom--right fixed-bottom--boxed">
            <mercur-button class="btn btn-raised" :disabled="loading" @click="$emit('saveProduct')">
                <span>Save</span>
            </mercur-button>
            <mercur-button class="btn btn-raised btn-yellow" :disabled="loading || !canProceed" @click="$emit('saveAndGenerateProduct')">
                <span>Save and generate</span>
                <i class="fas fa-angle-right" />
            </mercur-button>
        </portal>
    </div>
</template>

<script>
import CONFIG from '@root/config'
import StatusChip from '@/components/utils/StatusChip'

export default {
    name: 'ProductAttributeSelectorConfirmation',
    components: {
        StatusChip,
    },
    props: [
        'selectedFacilities',
        'selectedAttributes',
        'selectedBlacklists',
        'productConfigurationName',
        'productCategory',
        'loading',
        'getUrlForStep',
        'productId',
        'productConfigurationId',
        'productHash',
        'attibutesValidation',
    ],
    data () {
        return {
            canProceed: false,
            checkingValidity: false,
            validityErrors: null,
            configurationList: null,
        }
    },

    computed: {
        totalNumberOfVariations () {
            if (!this.selectedAttributes.length) {
                return null
            }

            return this.selectedAttributes.reduce((accumulator, currentValue) => accumulator * currentValue.options.length, 1)
        },
        configurationListProductNames () {
            if (!this.configurationList || this.configurationList.length === 0) {
                return ''
            }

            return this.configurationList.map(productConfiguration => productConfiguration.productConfigurationName)
        },
    },

    methods: {
        beautifyValue (value) {
            if (!value) {
                return value
            }
            return value.toString().charAt(0).toUpperCase() + value.slice(1).replace(/_/g, ' ')
        },
        checkProductAttributeUsage (selectedAttributes) {
            this.checkingValidity = true
            const payload = {
                productId: this.productId,
                supplierId: this.supplierId,
                productHash: this.productHash,
                productConfigurationId: this.productConfigurationId,
                attributeConfiguration: {
                    selectedAttributes,
                },
            }
            this.addJob(payload)
            this.canProceed = false
            this.$api.post(CONFIG.API.ROUTES.PRODUCTS.GENERATION.CHECK_VALIDITY, payload).then(({ data }) => {
                if (data.data === null) {
                    this.canProceed = false
                    this.validityErrors = ['Something went wrong']
                    return
                }
                this.validityErrors = data.data.errors || []
                this.configurationList = data.data.configurationList || []
                this.canProceed = !data.data.errors
            }).catch(error => {
                this.canProceed = false
                this.validityErrors = error
            }).finally(() => {
                this.checkingValidity = false
                this.finishJob(payload)
            })
        },
    },
    mounted () {
        if (this.attibutesValidation) {
            this.checkProductAttributeUsage(this.attibutesValidation)
        }
    },
}
</script>

<style lang="scss" scoped>
    .divider {
        margin-top: 20px;
    }
    .validity {
        vertical-align: middle;
    }
</style>
