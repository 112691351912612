import { minLength, required } from 'vuelidate/lib/validators'

export const cutoffTimeConfigurationsValidation = {
    required,
    minLength: minLength(1),
    $each: {
        facilityIds: {
            required,
            minLength: minLength(1),
        },
        countries: {
            required,
            minLength: minLength(1),
        },
        productionDays: {
            required,
            minLength: minLength(1),
        },
        cutoffTime: {
            time: {
                required,
            },
            daysOfWeek: {
                required,
                minLength: minLength(1),
            },
        },
    },
}
