<template>
    <div>
        <mercur-button class="btn btn-yellow btn-sm" @click="promptActive = true">Add</mercur-button>
        <mercur-dialog
            class="add-attribute-option-dialog"
            :is-open.sync="promptActive"
        >
            <div slot="header">
                Proposed attribute option
            </div>

            <mercur-input
                v-model="$v.proposedAttributeOption.$model"
                :class="{'form-invalid': $v.proposedAttributeOption.$error}"
            >
                <template slot="note">
                    <span class="form-error" v-if="!$v.proposedAttributeOption.attributeValidation">
                        Attribute option needs to match regex: <code>{{optionValidationRegex}}</code>
                    </span>
                </template>
            </mercur-input>
            <div slot="footer">
                <mercur-button class="btn" @click="promptActive = false">Cancel</mercur-button>
                <mercur-button
                    class="btn btn-yellow"
                    :disabled="$v.proposedAttributeOption.$invalid"
                    @click="proposeAttributeOptionSubmit"
                >Add</mercur-button>
            </div>
        </mercur-dialog>
    </div>
</template>
<script>

import Vue from 'vue'
import { required } from 'vuelidate/lib/validators'
import { getDeepProperty } from '../utils/Utils'

export default Vue.extend({
    name: 'AddAttributeOptionComponent',
    props: {
        passedMethod: {
            default: null,
        },
        parentKey: {
            default: null,
        },
        attributeValidations: {
            default: null,
        },
        attributeName: {
            default: null,
        },
    },
    data () {
        return {
            proposedAttributeOption: null,
            promptActive: false,
        }
    },
    computed: {
        optionValidationRegex () {
            if (this.resolvedAttributeValidations === null) {
                return null
            }
            const validationRegex = getDeepProperty('configuration.schema.regex', this.resolvedAttributeValidations[this.resolvedAttributeName], null)
            if (validationRegex === null) {
                return null
            }
            return validationRegex
        },
        resolvedAttributeValidations () {
            if (this.params && this.params.attributeValidations) {
                return this.params.attributeValidations()
            }
            return this.attributeValidations
        },
        resolvedAttributeName () {
            if (this.params && this.params.attributeName) {
                return this.params.attributeName()
            }
            return this.attributeName
        },
    },
    validations () {
        const validationRules = {
            proposedAttributeOption: {
                required,
            },
        }
        if (this.optionValidationRegex) {
            validationRules.proposedAttributeOption.regex = value => {
                if (typeof value === 'undefined' || value === null || value === '') {
                    return false
                }

                if (this.optionValidationRegex === null) {
                    return true
                }

                return new RegExp(this.optionValidationRegex).test(value)
            }
        }

        return validationRules
    },
    methods: {
        proposeAttributeOptionSubmit () {
            if (this.passedMethod) {
                this.passedMethod(JSON.parse(JSON.stringify(this.proposedAttributeOption)), this.parentKey)
                this.proposedAttributeOption = null
                return
            }
            this.params.proposeAttributeOption(JSON.parse(JSON.stringify(this.proposedAttributeOption)))
            this.proposedAttributeOption = null
        },
    },
})
</script>
