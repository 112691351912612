<template>
    <div v-if="isAllowedTo('SupplierCentral/saveProductConfiguration')" class="product-add full-height-layout fill">
        <mercur-stepper
            v-if="step !== 'completed'"
            :is-full="true"
            :activeStep.sync="activeStep"
            class="full-height-layout fill"
        >
            <mercur-step id="general" title="General">
                <mercur-card>
                    <h2>General</h2>
                    <div>
                        <mercur-input
                            required v-model="$v.productConfigurationName.$model" :disabled="loading"
                        >
                            Product name
                        </mercur-input>
                        <pretty-select
                            v-if="categories && categories.length"
                            placeholder="Product category"
                            :options="categories"
                            :reduce="category => category.productCategoryId"
                            label="categoryName"
                            v-model="$v.categoryId.$model"
                        ></pretty-select>
                        <pretty-select
                            v-if="!isVirtualSupplierProduct && productTemplates && productTemplates.length"
                            placeholder="Product template"
                            :options="productTemplates"
                            :reduce="productTemplate => productTemplate.productConfigurationId"
                            label="productConfigurationName"
                            v-model="$v.productConfigurationTemplateId.$model"
                        ></pretty-select>
                    </div>
                </mercur-card>
            </mercur-step>
            <mercur-step v-if="!isVirtualSupplierProduct" id='facilities' title="Facilities">
                <product-facilities-selector
                    :loading="loading"
                    :auto-select-first="true"
                    v-model="selectedFacilities"
                    v-show="!isVirtualSupplierProduct"
                ></product-facilities-selector>
            </mercur-step>
            <mercur-step id='attributes' title="Attributes">
                <product-attribute-selector
                    v-if="allAttributes"
                    :attributes="allAttributes"
                    :product-configuration-status="productData ? productData.productConfigurationStatus : 'DRAFT'"
                    :form-type="formType"
                    :key-attribute="keyAttribute"
                    @selection-change="handleSelectionChange"
                    :show-key-attribute="isVirtualSupplierProduct"
                ></product-attribute-selector>
            </mercur-step>
            <mercur-step id='blacklists' title="Blacklists">
                <component
                    :is="isOldTypeBlacklist ? 'ProductAttributeBlacklistSelector' : 'AdvancedProductAttributeBlacklistSelector'"
                    :selected-attributes="selectedAttributesWithOptions"
                    :selected-blacklists.sync="selectedBlacklists"
                ></component>
            </mercur-step>
            <mercur-step v-if="!isVirtualSupplierProduct" id='packages' title="Packages">
                <mercur-card>
                    <h2>Packages</h2>
                    <product-package-selector
                        :loading="loading"
                        v-model="selectedPackageConfiguration"
                        :available-facilities="selectedFacilities"
                        :available-attributes="selectedAttributesWithOptions"
                    ></product-package-selector>
                </mercur-card>
            </mercur-step>
            <mercur-step v-if="!isVirtualSupplierProduct" id='cutoff' title="Cutoff">
                <product-cutoff-times-configurations
                    :selected-attributes="selectedAttributesWithOptions"
                    :selected-facilities="selectedFacilities"
                    :cutoff-time-configurations.sync="cutoffTimeConfigurations"
                    :v="$v.cutoffTimeConfigurations"
                ></product-cutoff-times-configurations>
            </mercur-step>
            <mercur-step v-if="!isVirtualSupplierProduct" id='confirmation' title="Confirmation">
                <product-attribute-selector-confirmation
                    :product-configuration-name="productConfigurationName"
                    :category-name="categoryName"
                    :product-configuration-status="productData ? productData.productConfigurationStatus : 'DRAFT'"
                    :selected-attributes="selectedAttributesWithOptions"
                    :selected-blacklists="selectedBlacklists"
                    :selected-facilities="selectedFacilities"
                    :product-id="productData ? productData.productId : ''"
                    :product-configuration-id="productData ? productData.productConfigurationId : ''"
                    :supplier-id="productData ? productData.supplierId : ''"
                    :product-hash="productData ? productData.productHash : ''"
                    :loading="loading"
                    @saveProduct="saveProduct()"
                    @saveAndGenerateProduct="saveAndGenerateProduct"
                    :getUrlForStep="getUrlForStep"
                    :attibutesValidation="attibutesToValidate"
                    ref="confirmation"
                ></product-attribute-selector-confirmation>
            </mercur-step>
        </mercur-stepper>
        <div v-else>
            <mercur-card>
                <div class="completed-header">
                    <h2>Product scheduled for generation</h2>
                </div>
                <p>The product will be processed soon. You can track the status in the product overview.</p>
                <mercur-button class="btn btn-yellow" :to="overviewRoute">Return to overview</mercur-button>
            </mercur-card>
        </div>
            <portal
                to="actionbar" v-if="step !== 'confirmation'"
                target-class="fixed-bottom--right fixed-bottom--boxed">
                <template v-if="canSaveProduct">
                    <mercur-button class="btn btn-raised" @click="saveProduct()" :disabled="isSaving">
                        <span>Save</span></mercur-button>
                    <mercur-button
                        class="btn btn-yellow continue-button"
                        @click.prevent="saveAndGoToNextStep(activeStep)"
                        :href="getUrlForStep(getNextStepName(activeStep))"
                        :disabled="$v[`step_${activeStep}`].$invalid || isSaving"
                    >
                        <span>Save and continue to {{ getNextStepName(activeStep) }}</span>
                        <i class="fas fa-arrow-right"></i>
                    </mercur-button>
                </template>
                <template v-else>
                    <mercur-button
                        class="btn btn-yellow continue-button"
                        @click.prevent="goToNextStep(activeStep)"
                        :href="getUrlForStep(getNextStepName(activeStep))"
                        :disabled="$v[`step_${activeStep}`].$invalid || isSaving"
                    >
                        <span>Continue to {{ getNextStepName(activeStep) }}</span>
                        <i class="fas fa-arrow-right"></i>
                    </mercur-button>
                </template>
            </portal>
        <mercur-dialog :is-open.sync="conflictingProductConfigurationsDialogActive">
            <h2>Could not save product configuration</h2>
            <div class="product-conflicts__description">
                <p>The configuration you are trying to save has invalid attribute options selected. Please remove one or
                    more of these options from this configuration and save again.</p>
            </div>
            <hr/>
            <div class="dialog-old__content product-conflicts">
                <h3>Conflicting products</h3>
                <div v-for="(conflict, key) in conflictingProductConfigurations" :key="key" class="product-conflict">
                    Product name: <strong>{{ conflict.configurationName }}</strong>
                    <ul class="product-conflict__attributes">
                        <li v-for="(attributeOptions, attributeKey) in conflict.issues" :key="attributeKey"
                            class="product-conflict__attribute">
                            <span class="product-conflict__option">
                                {{ attributeKey | beautify }}: <strong>{{ attributeOptions | beautify | join(', ') }}</strong>
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
            <mercur-button class="btn btn-yellow" @click="conflictingProductConfigurationsDialogActive = false">Close
            </mercur-button>
        </mercur-dialog>
    </div>
    <div v-else>
        <p class="permission-message">Not allowed to see this view</p>
    </div>
</template>

<script>

import CONFIG from '@root/config'
import ProductAttributeSelector from '@/components/products/ProductAttributeSelector'
import ProductAttributeBlacklistSelector from '@/components/products/ProductAttributeBlacklistSelector'
import AdvancedProductAttributeBlacklistSelector from '@/components/products/AdvancedProductAttributeBlacklistSelector'
import ProductAttributeSelectorConfirmation from '@/components/products/ProductAttributeSelectorConfirmation'
import collect from 'collect.js'
import {
    required,
    minLength,
    requiredIf,
} from 'vuelidate/lib/validators'
import naturalCompare from 'natural-compare'
import ProductFacilitiesSelector from '@/components/products/ProductFacilitiesSelector'
import ProductCutoffTimesConfigurations from '../../../components/products/ProductCutoffTimesConfigurations'
import ProductPackageSelector from '../../../components/products/ProductPackageSelector'
import { cutoffTimeConfigurationsValidation } from '../../../components/utils/HelperValidations.js'
import { mapGetters, mapState } from 'vuex'
import PrettySelect from '../../../components/utils/PrettySelect'

export default {
    name: 'SupplierProductsAdd',
    components: {
        PrettySelect,
        ProductPackageSelector,
        ProductCutoffTimesConfigurations,
        ProductFacilitiesSelector,
        ProductAttributeSelector,
        ProductAttributeBlacklistSelector,
        AdvancedProductAttributeBlacklistSelector,
        ProductAttributeSelectorConfirmation,
    },

    props: {
        previouslySelectedAttributes: {
            default: () => [],
        },
        previouslySelectedBlacklists: {
            default: () => [],
        },
        productDataPromise: {},
    },

    validations () {
        return {
            productConfigurationName: {
                required,
            },
            categoryId: {
                required: requiredIf(function () {
                    return this.categories
                }),
            },
            productConfigurationTemplateId: {
                required,
            },
            selectedFacilities: {
                required,
                minLength: minLength(1),
                $each: {
                    daysOfWeek: {
                        required,
                        minLength: minLength(1),
                    },
                    productionDays: {
                        required,
                        minLength: minLength(1),
                    },
                    serviceLevels: {
                        required,
                        minLength: minLength(1),
                    },
                    packageConfigurations: {
                        $each: CONFIG.VALIDATIONS.PACKAGES.PACKAGE_CONFIGURATION,
                    },
                },
            },
            selectedAttributesWithOptions: {
                required,
            },
            cutoffTimeConfigurations: { ...cutoffTimeConfigurationsValidation },
            selectedPackageConfiguration: {
                $each: {
                    attributes: {},
                    facilities: {
                        required,
                    },
                    configurations: {
                        $each: CONFIG.VALIDATIONS.PACKAGES.PACKAGE_CONFIGURATION,
                    },
                },
            },
            ...(this.isVirtualSupplierProduct ? {
                step_general: ['productConfigurationName', 'categoryId'],
                step_attributes: ['step_general', 'selectedAttributesWithOptions'],
                step_blacklists: ['step_attributes'],
                step_facilities: ['selectedFacilities'],
            } : {
                step_general: ['productConfigurationName', 'categoryId', 'productConfigurationTemplateId'],
                step_attributes: ['step_general', 'selectedAttributesWithOptions'],
                step_blacklists: ['step_attributes'],
                step_packages: ['step_attributes', 'selectedPackageConfiguration'],
                step_cutoff: ['cutoffTimeConfigurations'],
                step_facilities: ['selectedFacilities'],
                step_confirmation: ['step_attributes'],
                step_completed: [],
            }),

        }
    },

    data () {
        return {
            cloneProductConfigurationId: null,
            cloneSupplierId: null,
            allAttributes: null,
            selectedBlacklists: [],
            selectedFacilities: null,
            selectedPackageConfiguration: null,
            cutoffTimeConfigurations: null,
            success: null,
            loading: false,
            productConfigurationName: '',
            categoryId: null,
            productConfigurationTemplateId: null,
            isSaving: false,
            activeStep: 'general',
            isOldTypeBlacklist: false,
            conflictingProductConfigurationsDialogActive: false,
            conflictingProductConfigurations: [],
            attibutesToValidate: [],
            keyAttribute: null,
        }
    },

    computed: {
        ...mapState('productContent', ['categories']),
        ...mapState('products', ['productTemplates']),
        ...mapGetters('auth', ['user']),
        isVirtualSupplierProduct () {
            return this.user.supplierType === 'VIRTUAL_SUPPLIER' && this.$route.params.supplierId === this.user.supplierId
        },
        steps () {
            if (this.isVirtualSupplierProduct) {
                return [
                    'general',
                    'attributes',
                    'blacklists',
                ]
            }
            return [
                'general',
                'facilities',
                'attributes',
                'blacklists',
                'packages',
                'cutoff',
                'confirmation',
            ]
        },
        items () {
            return this.$store.state.productAttributes.productAttributes
        },
        formType () {
            return this.$route.name === 'SupplierProductsEdit' ? 'edit' : 'new'
        },
        step () {
            const step = this.$route.params.productAddStep || 'general'
            return step
        },
        overviewRoute () {
            return {
                name: 'SupplierProducts',
                params: {
                    supplierId: this.supplierId,
                },
            }
        },
        selectedAttributesWithOptions () {
            if (this.allAttributes === null) {
                return []
            }

            return this.allAttributes.filter(attribute => attribute.selected && attribute.options.some(option => option.selectedValue)).map(attribute => {
                return {
                    ...attribute,
                    options: attribute.options.filter(option => option.selectedValue).map(option => {
                        this.$set(option, 'selectedValue', option.option)
                        return option
                    }),
                }
            })
        },
        productData () {
            const supplierId = this.cloneProductConfigurationId ? this.cloneSupplierId : this.supplierId
            const productConfigurationId = this.cloneProductConfigurationId ? this.cloneProductConfigurationId : this.$route.params.productConfigurationId

            const resource = this.$store.state.products.products[`${supplierId}_${productConfigurationId}`]

            if (resource) {
                return resource
            }

            return null
        },
        canSaveProduct () {
            if (this.$route.params.productConfigurationId) {
                return true
            }

            if (this.step === 'general' || this.step === 'facilities') {
                return false
            }

            return true
        },
        categoryName () {
            if (!this.categoryId || !this.categories) {
                return null
            }

            return this.categories.find(e => e.productCategoryId === this.categoryId).categoryName
        },
    },

    methods: {
        handleSelectionChange (newSelection) {
            this.keyAttribute = newSelection
        },
        getUrlForStep (stepName) {
            return this.$router.resolve({
                name: this.$route.name,
                params: {
                    ...this.$route.params,
                    productAddStep: stepName,
                },
            }).href
        },
        getNextStepName (currentStep = null) {
            const currentStepIndex = this.steps.indexOf(currentStep || this.activeStep)
            return this.steps[currentStepIndex + 1]
        },

        goToNextStep (currentStep) {
            this.activeStep = this.steps[this.steps.indexOf(currentStep) + 1]
        },

        saveAndGoToNextStep (currentStep) {
            this.saveProduct().then(() => {
                this.goToNextStep(currentStep)
            })
        },

        getCleanedSelectedAttributesWithOptions () {
            return collect(JSON.parse(JSON.stringify(this.selectedAttributesWithOptions))).mapWithKeys(item => {
                const options = collect(item.options).map((option) => {
                    const o = {
                        option: option.option,
                        postfix: option.postfix,
                    }
                    if (option.option !== option.selectedValue) {
                        o.alternative = option.selectedValue
                    }
                    return o
                })

                return [item.attributeKey, options.all()]
            }).all()
        },

        getCleanedSelectedBlacklists () {
            if (this.isOldTypeBlacklist) {
                return collect(this.selectedBlacklists).map((item) => {
                    return collect(item.raw).map(attribute => attribute.selectedValue).all()
                }).all()
            } else {
                return this.selectedBlacklists.map((item) => {
                    const rawObj = {}
                    if (Array.isArray(item.raw)) {
                        item.raw.forEach((elem) => {
                            if (rawObj[elem.attributeKey]) {
                                rawObj[elem.attributeKey].push(elem.selectedValue)
                            } else {
                                rawObj[elem.attributeKey] = [elem.selectedValue]
                            }
                        })
                    } else {
                        Object.entries(item.raw).forEach(([attributeKey, attributeValues]) => {
                            if (rawObj[attributeKey]) {
                                rawObj[attributeKey].push(...attributeValues.option)
                            } else {
                                rawObj[attributeKey] = [...attributeValues.option]
                            }
                        })
                    }

                    return rawObj
                })
            }
        },

        saveProduct (intentGenerate = false) {
            const productData = JSON.parse(JSON.stringify({
                productConfigurationName: this.productConfigurationName,
                categoryId: this.categoryId,
                productConfigurationTemplateId: this.productConfigurationTemplateId,
                categoryName: this.categoryName,
                selectedAttributesWithOptions: this.getCleanedSelectedAttributesWithOptions(),
                blacklists: this.getCleanedSelectedBlacklists(),
                selectedFacilities: this.selectedFacilities,
                selectedPackageConfiguration: this.isVirtualSupplierProduct ? { autoGenerated: false } : this.selectedPackageConfiguration,
                cutoffTimeConfigurations: this.cutoffTimeConfigurations,
                productConfigurationStatus: this.productData ? this.productData.productConfigurationStatus : 'DRAFT',
                isVirtualSupplierProduct: this.isVirtualSupplierProduct,
            }))

            if (this.isVirtualSupplierProduct) {
                productData.keyAttribute = this.keyAttribute
                productData.selectedFacilities = productData.selectedFacilities.map(facility => {
                    facility.productionDays = {
                        days: 1,
                        steps: 1,
                        from: 1,
                        to: 1,
                    }
                    return facility
                })
            }

            if (intentGenerate || (this.productData && this.productData.productConfigurationStatus === 'PUBLISHED')) {
                productData.productConfigurationStatus = 'UNPUBLISHED'
            }

            let url = CONFIG.API.ROUTES.PRODUCTS.GENERATION.ADD.replace('{supplierId}', this.supplierId)
            if (this.formType === 'edit') {
                url = CONFIG.API.ROUTES.PRODUCTS.GENERATION.SAVE.replace('{supplierId}', this.supplierId).replace('{productConfigurationId}', this.$route.params.productConfigurationId)
            }

            const jobName = productData.productConfigurationName
            this.addJob(jobName)
            this.loading = true
            this.isSaving = true

            return new Promise((resolve, reject) => {
                this.conflictingProductConfigurations = []
                this.conflictingProductConfigurationsDialogActive = false
                this.$api.post(url, productData).then(({ data }) => {
                    this.success = data.message || 'Success.'

                    if (!intentGenerate) {
                        this.$root.$emit('notification:global', {
                            message: 'Product saved successfully',
                        })
                    }

                    if (!this.$route.params.productConfigurationId && data.data.productConfigurationId) {
                        this.$router.replace({
                            name: 'SupplierProductsEdit',
                            params: {
                                ...this.$route.params,
                                productConfigurationId: data.data.productConfigurationId,
                            },
                        }, () => {
                            resolve(data)
                        })
                        return
                    }
                    resolve(data)
                }).catch(err => {
                    if (err.data.conflicts) {
                        this.conflictingProductConfigurations = err.data.conflicts
                        this.conflictingProductConfigurationsDialogActive = true

                        reject(err)
                        return
                    }
                    this.$root.$emit('notification:global', {
                        message: err.data.defaultMessage || 'Something went wrong while saving the product. Please try again.',
                        type: 'error',
                    })
                    this.errors = err.data
                    this.$emit('error', err.data)
                    reject(err)
                }).finally(() => {
                    if (!intentGenerate) {
                        this.isSaving = false
                    }
                    this.loading = false
                    this.finishJob(jobName)
                })
            })
        },

        generateProduct () {
            const url = CONFIG.API.ROUTES.PRODUCTS.GENERATION.GENERATE.replace('{supplierId}', this.supplierId).replace('{productConfigurationId}', this.$route.params.productConfigurationId)

            const jobName = url
            this.addJob(jobName)
            this.loading = true

            return this.$api.post(url).then(({ data }) => {
                this.success = data.message || 'Success.'
                this.$root.$emit('notification:global', {
                    message: 'Product generation scheduled successfully',
                })
                this.$router.push(this.getUrlForStep('completed'))
            }).catch(err => {
                this.$root.$emit('notification:global', {
                    message: err.data.exceptions[0].error,
                    type: 'error',
                })
                this.errors = err.data
                this.$emit('error', err.data)
            }).finally(() => {
                this.loading = false
                this.isSaving = false
                this.finishJob(jobName)
            })
        },
        saveAndGenerateProduct () {
            this.saveProduct(true).then(() => {
                this.generateProduct()
            })
        },
    },
    watch: {
        selectedAttributesWithOptions: {
            handler () {
                if (this.activeStep === 'attributes' && this.selectedAttributesWithOptions.length === 0) {
                    this.$v.selectedAttributesWithOptions.$touch()
                }
            },
            deep: true,
        },
        activeStep (val) {
            const url = this.getUrlForStep(val)
            this.$router.push(url)
            return val
        },
    },
    mounted () {
        if (this.step === 'confirmation') {
            this.attibutesToValidate = this.getCleanedSelectedAttributesWithOptions()
        }
    },
    created () {
        if (this.$route.params.productAddStep) {
            this.activeStep = this.$route.params.productAddStep
        }

        if (!this.isAllowedTo('SupplierCentral/saveProductConfiguration')) {
            return
        }

        const postfixPromise = this.$store.dispatch('productAttributes/fetchPostfixes')

        if (this.$route.query.cloneProductConfigurationId && this.$route.query.cloneSupplierId) {
            this.cloneProductConfigurationId = this.$route.query.cloneProductConfigurationId
            this.cloneSupplierId = this.$route.query.cloneSupplierId
        }

        const productAttributePromise = this.$store.dispatch('productAttributes/fetchProductAttributes').then(({ data }) => {
            const rawAttributes = data
            this.$set(this, 'allAttributes', Object.keys(rawAttributes).map((attributeKey) => {
                const attributeName = this.$options.filters.beautify(attributeKey)

                const options = rawAttributes[attributeKey].map((attributeOption) => {
                    attributeOption.optionName = this.$options.filters.beautify(attributeOption.option)
                    return {
                        ...attributeOption,
                        selectedValue: null,
                    }
                }).sort((a, b) => naturalCompare(a.option, b.option))

                return {
                    attributeKey,
                    attributeName,
                    selected: false,
                    options,
                    configuration: rawAttributes[attributeKey][0].configuration,
                }
            }))
        })

        if (this.formType === 'new' && this.cloneProductConfigurationId === null) {
            this.selectedFacilities = []
            this.cutoffTimeConfigurations = []
            this.selectedPackageConfiguration = []
        } else {
            this.loading = true
            let productDetailsPromise

            if (this.cloneProductConfigurationId) {
                productDetailsPromise = this.$store.dispatch('products/getProductDetailsForSupplierAndProductConfigurationId', {
                    supplierId: this.cloneSupplierId,
                    productConfigurationId: this.cloneProductConfigurationId,
                })
            } else {
                productDetailsPromise = this.productDataPromise
            }

            productDetailsPromise.then(() => {
                this.productConfigurationName = this.productData.productConfigurationName
                this.$set(this, 'categoryId', this.productData.categoryId)
                this.$set(this, 'productConfigurationTemplateId', this.productData.productConfigurationTemplateId)

                if (this.cloneSupplierId === this.supplierId || this.cloneProductConfigurationId === null) {
                    this.selectedFacilities = JSON.parse(JSON.stringify([...this.productData.facilities]))
                } else {
                    this.selectedFacilities = []
                }

                if (this.cloneProductConfigurationId === null && this.productData.cutoffTimeConfigurations) {
                    this.cutoffTimeConfigurations = this.productData.cutoffTimeConfigurations
                } else {
                    this.cutoffTimeConfigurations = []
                }
                this.loading = false
            })

            Promise.all([postfixPromise, productAttributePromise, productDetailsPromise]).catch(console.error).then(() => {
                const selectedAttributes = this.productData.attributeConfiguration.selectedAttributes
                for (let attributeKey in selectedAttributes) {
                    if (!selectedAttributes.hasOwnProperty(attributeKey)) {
                        continue
                    }

                    let theAttributeIndex = this.allAttributes.findIndex((attribute) => attribute.attributeKey === attributeKey)
                    if (theAttributeIndex) {
                        this.$set(this.allAttributes[theAttributeIndex], 'selected', new Date().getTime())
                    }

                    selectedAttributes[attributeKey].forEach(selectedOption => {
                        try {
                            const attributeOptionIndex = this.allAttributes[theAttributeIndex].options.findIndex(option => option.option === selectedOption.option)
                            this.$set(this.allAttributes[theAttributeIndex].options[attributeOptionIndex], 'selectedValue', selectedOption.alternative || selectedOption.option)
                            this.$set(this.allAttributes[theAttributeIndex].options[attributeOptionIndex], 'postfix', selectedOption.postfix)
                        } catch (e) {
                            console.error(e)
                        }
                    })
                }

                const selectedBlacklists = this.productData.attributeConfiguration.blacklistedAttributes
                selectedBlacklists.forEach((blacklistSet, key) => {
                    const rawBlacklistSet = collect(blacklistSet).map((value) => {
                        return {
                            option: value,
                            selectedValue: value,
                        }
                    }).all()

                    const newBlacklistItem = collect(blacklistSet).filter((item, key) => key !== 'hashId').map((value, attributeKey) => {
                        const attribute = this.selectedAttributesWithOptions.find(attribute => attribute.attributeKey === attributeKey)
                        if (!attribute) {
                            return
                        }

                        return {
                            attributeName: attribute.attributeName,
                            value: this.$options.filters.beautify(value),
                        }
                    }).toArray()

                    if (newBlacklistItem.every((item) => item)) {
                        this.selectedBlacklists.unshift({
                            attributes: newBlacklistItem,
                            raw: rawBlacklistSet,
                            key: new Date().getTime() + key,
                        })
                    }
                })

                this.selectedPackageConfiguration = this.productData.packageConfiguration || []
                this.keyAttribute = this.productData.keyAttribute || null

                // this is a fallback for products with old way of storing blacklist, should be removed later
                if (this.selectedBlacklists.length && (typeof this.selectedBlacklists[0].attributes[0].value === 'string' || this.selectedBlacklists[0].attributes[0].value instanceof String)) {
                    this.isOldTypeBlacklist = true
                }
            })
        }

        this.$store.dispatch('productContent/getProductContentCategories')
    },
}
</script>

<style lang="scss" scoped>
.product-add {
    padding-left: 7px;
    padding-right: 7px;
    padding-top: 7px;
}

.stepper-content {
    padding-bottom: 0;
}

.stick-element {
    position: sticky;
    bottom: 0;
}

.checkmark-icon {
    float: right;
    font-size: 100px !important;
    margin-left: auto;
}

.product-conflicts {
    margin-top: 10px;
    padding-bottom: 0;

    &__description {
        padding-bottom: 0;
    }
}

.product-conflict {
    &__attributes {
        padding-left: 0;
    }

    &__attribute {
        text-indent: -35px;
        padding-left: 50px;
        list-style-position: inside;
        margin-left: -10px;
        margin-bottom: 6px;
        font-size: 12px;
        line-height: 1.4em;
    }
}

</style>
